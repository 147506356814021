import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IDocumentContactChange } from "../../models/documentContactChangeModel/IDocumentContactChange";

export interface IFormResults {
  _id: string;
  selected: boolean;
  formTitle: string;
  formNumber: string;
  office: string;
  contactName: string;
}

export interface IProcedureResults {
  _id: string;
  selected: boolean;
  title: string;
  procedureNumber: string;
  office: string;
  contactName: string;
}

const baseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/document-contact-change`;

export const documentContactChangeApi = createApi({
  reducerPath: "documentContactChange",
  baseQuery: fetchBaseQuery({ baseUrl, credentials: "include" }),
  tagTypes: [
    "document-contact-change",
    "documents-by-contact",
    "documents-by-office",
  ],
  endpoints: (builder) => ({
    getDocumentContactChanges: builder.query<IDocumentContactChange[], void>({
      query: () => baseUrl,
      providesTags: ["document-contact-change"],
    }),
    getDocumentContactChangeById: builder.query<IDocumentContactChange, string>(
      {
        query: (id) => `${baseUrl}/${id}`,
        providesTags: (result, error, id) => [
          { type: "document-contact-change", id },
        ],
      }
    ),
    getContactChangesByContact: builder.query<
      { forms: IFormResults[]; procedures: IProcedureResults[] },
      number
    >({
      query: (id) => `${baseUrl}/contact/${id}`,
      providesTags: (result, error, id) => [
        { type: "documents-by-contact", id },
      ],
    }),
    getContactChangesByOffice: builder.query<
      { forms: IFormResults[]; procedures: IProcedureResults[] },
      string
    >({
      query: (office) => `${baseUrl}/office/${office}`,
      providesTags: (result, error, office) => [
        { type: "documents-by-office", office },
      ],
    }),
    addDocumentContactChange: builder.mutation<
      IDocumentContactChange,
      IDocumentContactChange
    >({
      query: (body) => ({
        url: baseUrl,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["document-contact-change"],
    }),
    publishDocumentContactChange: builder.mutation<
      IDocumentContactChange,
      IDocumentContactChange
    >({
      query: (body) => ({
        url: `${baseUrl}/${body._id}`,
        body,
        method: "PATCH",
      }),
      invalidatesTags: ["document-contact-change"],
    }),
    markAsVoidDocumentContactChange: builder.mutation<
      IDocumentContactChange,
      string
    >({
      query: (id) => ({
        url: `${baseUrl}/mark-as-void/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["document-contact-change"],
    }),
  }),
});

export const {
  useGetDocumentContactChangesQuery,
  useGetDocumentContactChangeByIdQuery,
  useGetContactChangesByContactQuery,
  useGetContactChangesByOfficeQuery,
  useAddDocumentContactChangeMutation,
  usePublishDocumentContactChangeMutation,
  useMarkAsVoidDocumentContactChangeMutation,
} = documentContactChangeApi;
