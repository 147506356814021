import { IForm } from "../../models/formModels/IForm";
import { IFormGridModel } from "../../models/formModels/IFormGridModel";
import {
  utcToDateString,
  formAttachmentHelper,
  utcToISODateString,
} from "../../utils/stringUtils";
import { IStaff } from "procedural-document-library-shared";
import { IProcedure } from "../../models/procedureModels/IProcedure";
import { IProcedureGridModel } from "../../models/procedureModels/IProcedureGridModel";
import { IVersion } from "../../models/procedureModels/IVersion";
import { IFileAttachment } from "../../models/IAttachment";
import { IComment } from "../../models/formModels/IComment";
import { ICommentGridModel } from "../../models/formModels/ICommentGridModel";
import moment from "moment";
import { IReview } from "../../models/reviewModels/IReview";
import { IReviewGridModel } from "../../models/reviewModels/IReviewGridModel";
import { IReviewCommentGridModel } from "../../models/reviewModels/IReviewCommentGridModel";
import { IReviewComment } from "../../models/reviewModels/IReviewComment";
import { IStatus } from "../../models/procedureModels/IStatus";
import { IOffice } from "../../models/officeModels/IOffice";
import { IProcedureContactChangeModel } from "../../models/procedureModels/IProcedureContactChangeModel";
import {
  IFormResults,
  IProcedureResults,
} from "../../redux/slices/documentContactChangeApi";
import { IHomepageDocument } from "../../models/IHomepageDocument";
import { IHomepageDocumentGridModel } from "../../models/IHomepageDocumentGridModel";
import { IContactDocumentOwnerGridModel } from "../../models/contactDocumentOwnerModels/contactDocumentOwnerGridModel";

const momentDateTimeFormat = "MM/DD/YYYY hh:mm:ss A";

const checkIfStatusUpdated = (
  isStatusUpdated = false,
  showStatusUpdatedDate: Date | null | undefined
) => {
  if (isStatusUpdated && showStatusUpdatedDate) {
    const thirtyDaysAgoDate = new Date();
    thirtyDaysAgoDate.setDate(new Date().getDate() - 30);
    thirtyDaysAgoDate.setHours(0, 0, 0, 0);
    const updatedDateTime = new Date(showStatusUpdatedDate).setHours(
      0,
      0,
      0,
      0
    );

    return thirtyDaysAgoDate.getTime() <= updatedDateTime;
  } else {
    return false;
  }
};

export const getStatusUpdatedForms = (forms: IForm[]) => {
  const statusUpdatedForms: IForm[] = [];
  forms.forEach((x) => {
    if (checkIfStatusUpdated(x.showStatusUpdated, x.showStatusUpdatedDate)) {
      statusUpdatedForms.push(x);
    }
  });
  return statusUpdatedForms;
};

//notes column in the forms grid
const getNotesForForm = (
  isStatusUpdated = false,
  showStatusUpdatedDate: Date | null | undefined,
  obsoleteDate: string | undefined
) => {
  let notes = "";

  if (checkIfStatusUpdated(isStatusUpdated, showStatusUpdatedDate)) {
    notes = "Updated";
  }

  if (obsoleteDate !== undefined && obsoleteDate !== null) {
    const now = new Date().setHours(0, 0, 0, 0);
    const obsolete = new Date(utcToDateString(obsoleteDate)).setHours(
      0,
      0,
      0,
      0
    );
    if (obsolete < now) {
      if (notes === "") {
        notes = "*Obsolete*";
      } else {
        notes += " *Obsolete*";
      }
    }
  }

  return notes;
};

const getFullName = (contact: IStaff | null | undefined) => {
  if (
    contact !== null &&
    contact !== undefined &&
    contact.firstName !== null &&
    contact.lastName !== null
  ) {
    return `${contact.firstName} ${contact.lastName}`;
  } else {
    return "";
  }
};

const getContactEmail = (contact: IStaff | null | undefined) => {
  if (contact) {
    return `${contact.emailAddress}`;
  } else {
    return "";
  }
};

const getProcedureNumberTitle = (procedure: IProcedure | null | undefined) => {
  if (procedure === null || procedure === undefined) {
    return "";
  }

  return `${procedure.procedureNumber}`;
};

const getResponsibleOffice = (responsibleOffice: any) => {
  if (responsibleOffice === null || responsibleOffice === undefined) return "";
  return (responsibleOffice as IOffice).description;
};

export const mapFormGridData = (formsList: IForm[]): Array<IFormGridModel> => {
  return formsList.map((form) => ({
    ...form,
    formattedRevisionDate: !isNaN(
      Date.parse(utcToDateString(form.revisionDate))
    )
      ? new Date(Date.parse(utcToDateString(form.revisionDate)))
      : null,
    attachmentTypes: formAttachmentHelper(
      form.attachments,
      form.externalUrl,
      form.consolidatedForm,
      form.externalReferenceMessage
    ),
    selected: false,
    fullName: getFullName(form.contact),
    procedureNumber: getProcedureNumberTitle(form.relatedProcedure),
    notes: getNotesForForm(
      form.showStatusUpdated,
      form.showStatusUpdatedDate,
      form.obsoleteDate
    ),
    formattedStatusUpdatedDate: !isNaN(
      Date.parse(utcToDateString(form.showStatusUpdatedDate))
    )
      ? new Date(Date.parse(utcToDateString(form.showStatusUpdatedDate)))
      : null,
    responsibleOffice: getResponsibleOffice(form.responsibleOffice),
  }));
};

export const mapFormAndProcedureGridData = (
  formsList: IForm[],
  proceduresList: IProcedure[],
  typeToProcess?: string | undefined
): Array<IContactDocumentOwnerGridModel> => {
  let formsAndProcedures: IContactDocumentOwnerGridModel[] = [];

  if (typeToProcess === "Procedure") {
    proceduresList.forEach((p) => {
      formsAndProcedures.push({
        selected: false,
        fullName: getFullName(p.contact),
        email: p.contact?.emailAddress || "",
        programArea: p.programArea,
        office: p.office,
        procedureNumber: p.procedureNumber,
        title: p.title,
        id: p._id,
        documentType: "Procedure",
      });
    });
  } else if (typeToProcess === "form") {
    formsList.forEach((f) => {
      formsAndProcedures.push({
        selected: false,
        fullName: getFullName(f.contact),
        email: f.contact?.emailAddress || "",
        programArea: f.programArea,
        office: f.office,
        formNumber: f.formNumber,
        title: f.formTitle,
        id: f._id,
        documentType: "Form",
      });
    });
  } else {
    proceduresList.forEach((p) => {
      formsAndProcedures.push({
        selected: false,
        fullName: getFullName(p.contact),
        email: p.contact?.emailAddress || "",
        programArea: p.programArea,
        office: p.office,
        procedureNumber: p.procedureNumber,
        title: p.title,
        id: p._id,
        documentType: "Procedure",
      });
    });
    formsList.forEach((f) => {
      formsAndProcedures.push({
        selected: false,
        fullName: getFullName(f.contact),
        email: f.contact?.emailAddress || "",
        programArea: f.programArea,
        office: f.office,
        formNumber: f.formNumber,
        title: f.formTitle,
        id: f._id,
        documentType: "Form",
      });
    });
  }

  return formsAndProcedures;
};

export const mapContactChangeFormData = (forms: IForm[]): IFormGridModel[] => {
  return forms.map((f) => {
    return {
      ...f,
      selected: (f as any).selected as boolean,
      notes: "",
    };
  });
};

export const mapFormCommentsGridData = (
  comments: Array<IComment>
): Array<ICommentGridModel> => {
  return comments.map((comment) => ({
    ...comment,
    createdByFullName: getFullName(comment.createdBy),
    updatedByFullName: getFullName(comment.updatedBy),
    formattedISOActionDate: utcToISODateString(comment.actionDate),
    formattedActionDate: utcToDateString(comment.actionDate),
    formattedCreatedDate: moment(comment.createdDate).format(
      momentDateTimeFormat
    ),
    formattedUpdatedDate: moment(comment.updatedDate).format(
      momentDateTimeFormat
    ),
  }));
};

export const mapHomepageGridData = (
  documents: Array<IHomepageDocument>,
  offices: Array<IOffice> | undefined
): Array<IHomepageDocumentGridModel> => {
  return documents.map((document) => ({
    ...document,
    formattedContactPerson:
      document.createdBy !== null
        ? document.createdBy?.lastName + ", " + document.createdBy?.firstName
        : "",
  }));
};

export const mapReviewsGridData = (
  reviews: Array<IReview>,
  offices: Array<IOffice> | undefined
): Array<IReviewGridModel> => {
  return reviews.map((review) => ({
    ...review,
    formattedProcedure: "",
    formattedBeginDate: !isNaN(Date.parse(utcToDateString(review.beginDate)))
      ? new Date(Date.parse(utcToDateString(review.beginDate)))
      : null,
    formattedEndDate: !isNaN(Date.parse(utcToDateString(review.endDate)))
      ? new Date(Date.parse(utcToDateString(review.endDate)))
      : null,
    procedureNumber: getProcedureNumberTitle(review.procedure),
    formattedCoordinators:
      review.coordinators !== undefined
        ? allCoordinators(review.coordinators)
        : "",
    formattedContactPerson:
      review.procedure?.contact !== null
        ? review.procedure?.contact.lastName +
          ", " +
          review.procedure?.contact.firstName
        : "",
    responsibleOfficeName:
      offices?.find((o) => o._id === review.procedure?.responsibleOffice)
        ?.description ?? "",
    formattedRespondedToPdlComments:
      review.respondedToPdlComments === true ? "Yes" : "No",
    formattedEffectiveDate: !isNaN(
      Date.parse(utcToDateString(review.effectiveDate))
    )
      ? new Date(Date.parse(utcToDateString(review.effectiveDate)))
      : null,
    formattedExecutiveWorkshopDate: !isNaN(
      Date.parse(utcToDateString(review.executiveWorkshopReviewDate))
    )
      ? new Date(
          Date.parse(utcToDateString(review.executiveWorkshopReviewDate))
        )
      : null,
    formattedExecutiveWorkshopDecisionDate: !isNaN(
      Date.parse(utcToDateString(review.executiveWorkshopDecisionDate))
    )
      ? new Date(
          Date.parse(utcToDateString(review.executiveWorkshopDecisionDate))
        )
      : null,
    formattedAssistantSecretaryApprovalDate: !isNaN(
      Date.parse(utcToDateString(review.assistantSecretaryApprovalDate))
    )
      ? new Date(
          Date.parse(utcToDateString(review.assistantSecretaryApprovalDate))
        )
      : null,
    selected: review.readyForWorkshopReview ?? false,
    executiveWorkshopDocumentStatus:
      review.executiveWorkshopDocumentStatus ?? "",
    formattedPPMUDecisionDate: dateFormatter(
      review.ppmuApprovalDate
    ),
    formattedLegalStatusDecisionDate: dateFormatter(
      review.legalApprovalDate
    ),
    formattedDirectorStatusDecisionDate: dateFormatter(
      review.directorApprovalDate
    ),
    formattedChiefEquivalentStatusDecisionDate: dateFormatter(
      review.chiefEquivalentApprovalDate
    ),
    formattedAssistantSecretaryStatusDecisionDate: dateFormatter(
      review.assistantSecretaryApprovalDate
    ),
  }));
};

const dateFormatter = (dateOfApproval: string | undefined) => {
  const dateConverter = !isNaN(Date.parse(utcToDateString(dateOfApproval)))
    ? new Date(Date.parse(utcToDateString(dateOfApproval)))
    : null;

  return dateConverter;
};

const approvalFormatter = (
  approvalStatus: string | undefined,
  dateOfApproval: string | undefined
) => {
  let approvalValue = "";
  const dateConverter = !isNaN(Date.parse(utcToDateString(dateOfApproval)))
    ? new Date(Date.parse(utcToDateString(dateOfApproval)))
    : null;
  approvalValue = approvalStatus ?? "";
  if (approvalValue && dateConverter) {
    approvalValue += ` (${dateConverter.toLocaleDateString()})`;
  }

  return approvalValue;
};

export const mapReviewCommentsGridData = (
  comments: Array<IReviewComment>
): Array<IReviewCommentGridModel> => {
  return comments.map((comment) => ({
    ...comment,
    createdByFullName: getFullName(comment.createdBy),
    updatedByFullName: getFullName(comment.updatedBy),
    formattedCreatedDate: moment(comment.createdDate).format(
      momentDateTimeFormat
    ),
    formattedUpdatedDate: moment(comment.updatedDate).format(
      momentDateTimeFormat
    ),
    respondedByFullName: getFullName(comment.responseBy),
    formatedRespondedOn: moment(comment.responseOn).format(
      momentDateTimeFormat
    ),
  }));
};

const allCoordinators = (coordinators: IStaff[]): string => {
  const allCoordinatorsName: Array<string> = [];
  coordinators.forEach((staff) => {
    allCoordinatorsName.push(getFullName(staff));
  });
  return allCoordinatorsName.length > 0 ? allCoordinatorsName.join(",") : "";
};

export const mapProceduresContactChangeData = (
  procedures: IProcedure[],
  isEdit: boolean
): IProcedureContactChangeModel[] => {
  return procedures.map((p) => ({
    ...p,
    responsibleOffice: getResponsibleOffice(p.responsibleOffice),
    selected: isEdit ? ((p as any).selected as boolean) : false,
  }));
};

export const mapProceduresGridData = (
  proceduresList: IProcedure[],
  isAdmin: boolean,
  displayPlanned = false
): Array<IProcedureGridModel> => {
  return proceduresList.map((procedure) => ({
    ...procedure,
    status: procedureStatus(procedure.versions, isAdmin),
    attachments: currentVersionAttachments(
      procedure.versions,
      isAdmin,
      displayPlanned
    ),
    attachmentTypes: formAttachmentHelper(
      currentVersionAttachments(procedure.versions, isAdmin, displayPlanned),
      "",
      false
    ),
    formattedEffectiveDate: currentVersionEffectiveDate(
      procedure.versions,
      isAdmin
    ),
    formattedReviewedDate: currentVersionReviewDate(
      procedure.versions,
      isAdmin
    ),
    formattedScheduledReviewDate: currentVersionScheduledReviewDate(
      procedure.versions,
      isAdmin
    ),
    contactName: getFullName(procedure.contact),
    contactEmail: getContactEmail(procedure.contact),
    formattedPendingActionDate:
      procedure.versions.length > 0
        ? utcToDateString(procedure.versions[0].pendingActionDate)
        : "",

    formattedExpirationDate:
      procedure.versions.length > 0
        ? !isNaN(
            Date.parse(utcToDateString(procedure.versions[0].expirationDate))
          )
          ? new Date(
              Date.parse(utcToDateString(procedure.versions[0].expirationDate))
            )
          : null
        : null,
    formattedUserReviewDate:
      procedure.versions.length > 0
        ? !isNaN(
            Date.parse(utcToDateString(procedure.versions[0].userReviewDate))
          )
          ? new Date(
              Date.parse(utcToDateString(procedure.versions[0].userReviewDate))
            )
          : null
        : null,
    formattedSrnSentDate:
      procedure.versions.length > 0
        ? !isNaN(
            Date.parse(
              utcToDateString(
                procedure.versions[0].scheduledReviewNoticeSentDate
              )
            )
          )
          ? new Date(
              Date.parse(
                utcToDateString(
                  procedure.versions[0].scheduledReviewNoticeSentDate
                )
              )
            )
          : null
        : null,
    formattedResponseReceivedDate:
      procedure.versions.length > 0
        ? !isNaN(
            Date.parse(
              utcToDateString(procedure.versions[0].responseReceivedDate)
            )
          )
          ? new Date(
              Date.parse(
                utcToDateString(procedure.versions[0].responseReceivedDate)
              )
            )
          : null
        : null,
    formattedHistoryDate:
      procedure.versions.length > 0
        ? !isNaN(Date.parse(utcToDateString(procedure.versions[0].historyDate)))
          ? new Date(
              Date.parse(utcToDateString(procedure.versions[0].historyDate))
            )
          : null
        : null,
    pendingAction:
      procedure.versions.length > 0
        ? procedure.versions[0].pendingAction === undefined
          ? ""
          : procedure.versions[0].pendingAction
        : "",
    comments:
      procedure.versions.length > 0
        ? procedure.versions[0].comments === undefined
          ? ""
          : procedure.versions[0].comments
        : "",
    flags:
      procedure.versions.length > 0
        ? procedure.versions[0].flags === undefined
          ? ""
          : procedure.versions[0].flags
        : "",
    versionNumber:
      procedure.versions.length > 0 ? procedure.versions[0].versionName : "",
    responsibleOffice: getResponsibleOffice(procedure.responsibleOffice),
  }));
};

const procedureStatus = (
  versions: Array<IVersion>,
  isAdmin: boolean
): string => {
  if (versions !== undefined && versions.length > 0) {
    const currentVersion = versions.find(
      (i) =>
        i.status === IStatus.Current || i.status === IStatus.CurrentAndRevision
    );
    if (currentVersion !== undefined) {
      return currentVersion.status === IStatus.CurrentAndRevision
        ? IStatus.CurrentAndRevision
        : currentVersion.status;
    } else {
      if (isAdmin) {
        return versions[0].status;
      }
    }
  }
  return "";
};

const currentVersionEffectiveDate = (
  versions: Array<IVersion>,
  isAdmin: boolean
): Date | null => {
  if (versions !== undefined && versions.length > 0) {
    const currentVersion = versions.find(
      (i) =>
        i.status === IStatus.Current || i.status === IStatus.CurrentAndRevision
    );
    if (currentVersion !== undefined) {
      return !isNaN(Date.parse(utcToDateString(currentVersion.effectiveDate)))
        ? new Date(Date.parse(utcToDateString(currentVersion.effectiveDate)))
        : null;
    } else {
      if (isAdmin) {
        return !isNaN(Date.parse(utcToDateString(versions[0].effectiveDate)))
          ? new Date(Date.parse(utcToDateString(versions[0].effectiveDate)))
          : null;
      }
    }
  }
  return null;
};

const currentVersionReviewDate = (
  versions: Array<IVersion>,
  isAdmin: boolean
): Date | null => {
  if (versions !== undefined && versions.length > 0) {
    const currentVersion = versions.find(
      (i) =>
        i.status === IStatus.Current || i.status === IStatus.CurrentAndRevision
    );
    if (currentVersion !== undefined) {
      return !isNaN(Date.parse(utcToDateString(currentVersion.lastReviewDate)))
        ? new Date(Date.parse(utcToDateString(currentVersion.lastReviewDate)))
        : null;
    } else {
      if (isAdmin) {
        return !isNaN(Date.parse(utcToDateString(versions[0].lastReviewDate)))
          ? new Date(Date.parse(utcToDateString(versions[0].lastReviewDate)))
          : null;
      }
    }
  }
  return null;
};

const currentVersionScheduledReviewDate = (
  versions: Array<IVersion>,
  isAdmin: boolean
): Date | null => {
  if (versions !== undefined && versions.length > 0) {
    const currentVersion = versions.find(
      (i) =>
        i.status === IStatus.Current || i.status === IStatus.CurrentAndRevision
    );
    if (currentVersion !== undefined) {
      return !isNaN(Date.parse(utcToDateString(currentVersion.expirationDate)))
        ? new Date(Date.parse(utcToDateString(currentVersion.expirationDate)))
        : null;
    } else {
      if (isAdmin) {
        return !isNaN(Date.parse(utcToDateString(versions[0].expirationDate)))
          ? new Date(Date.parse(utcToDateString(versions[0].expirationDate)))
          : null;
      }
    }
  }
  return null;
};

const currentVersionAttachments = (
  versions: Array<IVersion>,
  isAdmin: boolean,
  required: boolean
): Array<IFileAttachment> => {
  if (versions !== undefined && versions.length > 0) {
    const currentVersion = versions.find(
      (i) =>
        i.status === IStatus.Current || i.status === IStatus.CurrentAndRevision
    );
    if (currentVersion !== undefined) {
      return currentVersion.attachments !== undefined
        ? currentVersion.attachments
        : [];
    } else {
      if (
        isAdmin &&
        versions[0].attachments !== undefined &&
        versions[0].attachments.length > 0
      ) {
        return versions[0].attachments;
      }
    }
  }
  return [];
};

export const mapContactNameForms = (
  forms: IFormResults[],
  isEdit: boolean
): IFormResults[] => {
  return forms.map((f) => {
    return {
      _id: f._id,
      selected: isEdit ? f.selected : false,
      formTitle: f.formTitle,
      formNumber: f.formNumber,
      office: f.office,
      contactName: f.contactName,
    };
  });
};

export const mapContactNameProcedures = (
  procedures: IProcedureResults[],
  isEdit: boolean
): IProcedureResults[] => {
  return procedures.map((p) => {
    return {
      _id: p._id,
      contactName: p.contactName,
      office: p.office,
      procedureNumber: p.procedureNumber,
      selected: isEdit ? p.selected : false,
      title: p.title,
    };
  });
};

export const mapOfficeForms = (
  forms: IFormResults[],
  isEdit: boolean
): IFormResults[] => {
  return forms.map((f) => {
    return {
      _id: f._id,
      selected: isEdit ? f.selected : false,
      formTitle: f.formTitle,
      formNumber: f.formNumber,
      office: f.office,
      contactName: f.contactName,
    };
  });
};

export const mapOfficeProcedures = (
  procedures: IProcedureResults[],
  isEdit: boolean
): IProcedureResults[] => {
  return procedures.map((p) => {
    return {
      _id: p._id,
      contactName: p.contactName,
      office: p.office,
      procedureNumber: p.procedureNumber,
      selected: isEdit ? p.selected : false,
      title: p.title,
    };
  });
};
